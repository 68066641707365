<template>
  <div class="multi-places-create">
    <!-- ToolBar -->
    <VuetifyToolBar
      title="Crear establecimiento"
      :hide-back-button="false"
      :hide-action-button="true"
    />
    <!-- Content -->
    <v-container>
      <CardContainer>
        <v-form novalidate @submit.prevent="onSubmit">
          <CardContainerElement>
            <template v-slot:title>Datos del establecimiento</template>
            <template v-slot:subtitle>
              Completa el siguiente formulario para generar un nuevo establecimiento asociado a tu
              misma cuenta.
            </template>
            <template v-slot:content>
              <v-row v-if="isSchweppes">
                <v-col>
                  <!-- code -->
                  <v-text-field
                    v-model="$v.formFields.companyKeysId.$model"
                    label="Código cliente"
                    type="text"
                    outlined
                    dense
                    color="grey"
                    hint="Este dato se lo proporciará Schweppes"
                    :persistent-hint="true"
                    :hide-details="!checkFieldErrors('companyKeysId').length"
                    :error-messages="checkFieldErrors('companyKeysId')"
                    @input="touchField('companyKeysId')"
                    @blur="touchField('companyKeysId')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- name -->
                  <v-text-field
                    v-model="$v.formFields.name.$model"
                    label="Nombre"
                    type="text"
                    outlined
                    dense
                    color="grey"
                    :hide-details="!checkFieldErrors('name').length"
                    :error-messages="checkFieldErrors('name')"
                    @input="touchField('name')"
                    @blur="touchField('name')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- phone1 -->
                  <v-text-field
                    v-model="$v.formFields.phone1.$model"
                    label="Telefono"
                    type="text"
                    outlined
                    dense
                    color="grey"
                    :hide-details="!checkFieldErrors('phone1').length"
                    :error-messages="checkFieldErrors('phone1')"
                    @input="touchField('phone1')"
                    @blur="touchField('phone1')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- Google maps autocomplete -->
                  <GoogleMapAutocomplete
                    v-model="$v.formFields.place.$model"
                    label="Dirección del establecimiento"
                    :error-messages="checkFieldErrors('place')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- Google maps -->
                  <div class="gm-wrapper">
                    <GoogleMapLoader ref="googleMapLoader">
                      <template slot-scope="{ google, map }">
                        <GoogleMapMarker
                          v-if="placeMarker"
                          :marker="placeMarker"
                          :google="google"
                          :map="map"
                        />
                      </template>
                    </GoogleMapLoader>
                  </div>
                </v-col>
              </v-row>
            </template>
          </CardContainerElement>
          <!-- action buttons -->
          <FormButtons
            accept-button-text="Crear"
            :accept-button-loading="formProcessing"
            @onClickCancelButton="handleCancelButton"
          />
        </v-form>
      </CardContainer>
    </v-container>
  </div>
</template>

<script>
// Constants
import { ADDONS, PACKAGES } from '@/constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import FormButtons from '@/components/ui/FormButtons'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import GoogleMapLoader from '@/components/ui/GoogleMapLoader'
import GoogleMapMarker from '@/components/ui/GoogleMapMarker'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { createPlace, getUrlPlace, updateAddOnConfigs } from '@/services/place'
import { getCompanyKeyById, updateCompanyKeyById } from '@/services/companyKeys'
import { createSubscription } from '@/services/subscription'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// utils
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'MultiPlacesCreate',
  components: {
    FormButtons,
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapAutocomplete,
    VuetifyToolBar,
    CardContainer,
    CardContainerElement
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        companyKeysId: null, // Solo para Schweppes
        name: null,
        phone1: null,
        place: null
      },
      formFieldsValidations: {
        companyKeysId: {
          required: 'Campo obligatorio',
          keyIsValid: 'La clave no existe o ya ha sido usada. Contacte con su responsable de área'
        },
        name: {
          required: 'Campo obligatorio'
        },
        phone1: {
          required: 'Campo obligatorio'
        },
        place: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        }
      },
      // Others
      placeMarker: null // used to draw the marker in the map
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    ...mapGetters('authentication', ['userData']),
    /**
     * Estamos bajo la aplicación de Schweppes
     */
    isSchweppes() {
      return getEnvironmentVariable('VUE_APP_BRAND') === 'schweppes'
    }
  },
  watch: {
    'formFields.place'(value) {
      if (value) {
        // Redibujamos la marca en el mapa
        this.setMarkerInMap(value)
      }
    }
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    ...mapActions('company', ['updateCompany']),
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Se retorna a la pagina anterior
     */
    handleCancelButton() {
      this.routerGoTo()
    },
    /**
     * Set marker to show in map
     *
     * @param {Object} place - place, address
     */
    setMarkerInMap(place) {
      this.placeMarker = {
        title: place.locality,
        label: place.locality,
        position: {
          lat: place.latitude,
          lng: place.longitude
        }
      }
      // Posicionamos el mapa en el centro de la búsqueda
      this.$refs.googleMapLoader.setCenterMap({
        lat: place.latitude,
        lng: place.longitude
      })
    },
    /**
     * Se procesa el formulario y se crea el nuevo establecimiento
     */
    async afterSubmit() {
      // Generar URL unica
      const { url } = await getUrlPlace(this.formFields.name)
      // Creamos un nuevo establecimiento
      const { place: placeCreated } = await createPlace({
        companies: this.userData.companies,
        users: { [this.userData.id]: true },
        url
      })

      // Suscripción sobre el establecimiento
      await createSubscription({
        subscription: {
          packageId:
            getEnvironmentVariable('VUE_APP_BRAND') === 'schweppes'
              ? PACKAGES.pro.value
              : PACKAGES.free.value,
          data: {
            amount: 0,
            currency: 'EUR',
            priceId: 'custom',
            period: 36500
          },
          companyId: this.companyData.id,
          placeId: placeCreated.id
        }
      })

      // Actualizamos configuraciones del establecimiento (place)
      await updateAddOnConfigs({
        id: placeCreated.id,
        addOn: {
          id: ADDONS.place,
          configFields: {
            name: this.formFields.name
          }
        }
      })

      // Actualizamos configuraciones del establecimiento (contact)
      await updateAddOnConfigs({
        id: placeCreated.id,
        addOn: {
          id: ADDONS.contact,
          configFields: {
            phone1: this.formFields.phone1,
            place: this.formFields.place
          }
        }
      })

      // Marcamos la "companyKeysId" como usada
      if (this.isSchweppes) {
        await updateCompanyKeyById({
          enabled: false,
          id: this.formFields.companyKeysId
        })
      }

      // Se asocia el nuevo establecimiento al usuario
      await this.updateUser({
        id: this.userData.id,
        places: { ...this.userData.places, [placeCreated.id]: true }
      })

      // Se asocia el nuevo establecimiento a la compañía
      await this.updateCompany({
        id: this.companyData.id,
        places: { ...this.companyData.places, [placeCreated.id]: true }
      })

      // Se le muestra al usuario que todo ha salido correctamente
      this.modifyAppAlert({
        text: 'Se ha creado el establecimiento correctamente',
        visible: true
      })

      // Se redirije a la lista de establecimientos
      this.routerPushTo({ name: 'multiPlacesList' })
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        name: { required },
        phone1: { required },
        place: { required }
      }
    }

    // Si este "addons" esta siendo usado
    // por un usuario de "schweppes" deberá
    // indicar el "companyKeysId"
    if (this.isSchweppes) {
      rules.formFields.companyKeysId = {
        required,
        keyIsValid: async (value) => {
          if (!value || value.length < 5) return false
          const companyKeysData = await getCompanyKeyById(value)
          if (!companyKeysData) return false
          return true
        }
      }
    }

    return rules
  }
}
</script>
<style lang="scss" scoped>
.multi-places-create {
  height: 100%;
  .gm-wrapper {
    height: 200px;
    background-color: $theme-background;
    border: 1px solid $theme_border_color;
  }
  .category-form {
    height: 100%;
  }
}
</style>
